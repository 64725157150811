var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mx-auto my-3 pt-3 row"},[_c('div',{class:{
      [_vm.$style.card__register]: true
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('success-icon')],1),_vm._m(0),_c('div',{staticClass:"mt-5"},[_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
          [_vm.$style.button__submit]: true,
        },attrs:{"size":"large"},on:{"click":function($event){$event.preventDefault();return _vm.goToPowerBiz.apply(null, arguments)}}},[(!_vm.loading)?_c('div',[_vm._v(" Masuk Ke PowerBiz ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c('div',{staticClass:"pbz-font-h6"},[_vm._v(" Kata Sandi"),_c('br'),_vm._v(" Anda Berhasil diubah ")])])
}]

export { render, staticRenderFns }