<template>
  <div class="text-center mx-auto my-3 pt-3 row">
    <div
      :class="{
        [$style.card__register]: true
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <success-icon />
      </div>

      <div class="my-5">
        <div class="pbz-font-h6">
          Kata Sandi<br />
          Anda Berhasil diubah
        </div>
      </div>

      <div class="mt-5">
        <a-button
          size="large"
          class="pbz-font body-md-bold text-center w-100 mt-3"
          :class="{
            [$style.button__submit]: true,
          }"
          @click.prevent="goToPowerBiz"
        >
          <div v-if="!loading"> Masuk Ke PowerBiz </div>
          <div v-if="loading"><a-icon type="loading" /> Loading </div>
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessIcon from '@/components/Icons/Success.vue'

export default {
  components: {
    SuccessIcon,
  },
  computed: {
    loading () {
      return this.$store.state.user.loading
    },
  },
  methods: {
    goToPowerBiz () {
      this.$store.dispatch('user/LOGINPROCESSING', {
        token: this.$store.state.user.accessToken,
      })
        .then(() => {
          this.$router.push({ path: '/dashboard' })
        })
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
