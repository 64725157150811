<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200" fill="none">
  <g clip-path="url(#clip0_4205_302)">
  <rect width="200" height="200" fill="white"/>
  <circle cx="91" cy="100" r="90" fill="url(#paint0_linear_4205_302)"/>
  <g style="mix-blend-mode:multiply">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M197.042 84.2622C199.872 89.2506 198.123 95.5887 193.134 98.4187L77.2686 143.383C72.6269 146.016 66.745 144.702 63.6645 140.345L35.9056 101.076C32.595 96.3929 33.7078 89.9125 38.3911 86.6019C43.0744 83.2913 49.5547 84.4041 52.8653 89.0874L75.1973 120.679L182.886 80.3541C187.874 77.5241 194.212 79.2738 197.042 84.2622Z" fill="#CCCCCC"/>
  </g>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M198.87 60.4028C201.754 65.2083 199.971 71.314 194.888 74.0402L76.8354 137.363C72.1061 139.9 66.1132 138.635 62.9745 134.437L34.6916 96.6079C31.3185 92.0963 32.4523 85.8536 37.224 82.6644C41.9957 79.4752 48.5983 80.5471 51.9714 85.0587L74.725 115.492L184.447 56.638C189.529 53.9117 195.987 55.5973 198.87 60.4028Z" fill="#FCFCFC"/>
  <path d="M33.0481 88.9371C33.2161 90.6754 33.8254 92.3949 34.9056 93.9231L62.6645 133.192C65.745 137.549 71.6269 138.863 76.2686 136.23L194.829 68.9991C197.223 67.6409 198.872 65.7631 199.625 63.5923C200.201 67.6887 198.28 71.9071 194.464 74.0722L76.2686 138.23C71.6269 140.863 65.745 139.549 62.6645 135.192L34.9056 95.9231C33.4108 93.8084 32.8178 91.3273 33.0481 88.9371Z" fill="#F2F2F2"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_4205_302" x1="1" y1="10" x2="91" y2="190" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF8585"/>
  <stop offset="1" stop-color="#FF0000"/>
  </linearGradient>
  <clipPath id="clip0_4205_302">
  <rect width="200" height="200" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>

<script>
export default {
  name: 'Success-Icon',
}
</script>
